import { Container, DiscordIcon, LogoIcon, Text, TwitterIcon } from "@fscrypto/ui";
import { Link } from "@remix-run/react";
import { RESOURCES, COMPANY, DATA_ACCESS, INSIGHTS } from "../utils/shell-values";
import { LinkedinIcon } from "lucide-react";

const navigation = {
  social: [
    {
      name: "Twitter",
      href: "https://twitter.com/flipsidecrypto",
      icon: <TwitterIcon />,
    },
    {
      name: "Discord",
      href: "https://discord.gg/ZmU3jQuu6W",
      icon: <DiscordIcon />,
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/flipside-crypto",
      icon: <LinkedinIcon className="size-4" />,
    },
  ],
};

export default function Footer() {
  return (
    <footer className="bg-muted relative bottom-0" aria-labelledby="footer-heading">
      <Container>
        <div className="grid grid-cols-1 py-6 md:grid-cols-3 md:py-10">
          <div className="mb-6 space-y-6">
            <Link to="/" className="flex items-center space-x-2 text-lg dark:text-white">
              <LogoIcon className="fill-contrast h-6" />
              <span className="text-xl font-medium">flipside</span>
            </Link>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="sr-only">{item.name}</span>
                  {item.icon}
                </a>
              ))}
            </div>
          </div>

          <div className="col-span-2 grid grid-cols-2 gap-20 whitespace-nowrap pr-6 sm:grid-cols-4">
            <div>
              <Text as="h3" color="contrast" size="sm">
                Data
              </Text>
              <ul className="mt-3 space-y-3">
                {DATA_ACCESS.map((item) => (
                  <li key={item.to}>
                    <Text size="sm" color="muted" asChild>
                      <Link to={item.to}>{item.title}</Link>
                    </Text>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <Text as="h3" color="contrast" size="sm">
                Insights
              </Text>
              <ul className="mt-3 space-y-3">
                {INSIGHTS.map((item) => (
                  <li key={item.to}>
                    <Text size="sm" color="muted" asChild>
                      <Link to={item.to} target={item.target}>
                        {item.title}
                      </Link>
                    </Text>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <Text as="h3" color="contrast" size="sm">
                Resources
              </Text>
              <ul className="mt-3 space-y-3">
                {RESOURCES.map((item) => (
                  <li key={item.href}>
                    <Text size="sm" color="muted" asChild>
                      <a href={item.href} target="_blank" rel="noreferrer">
                        {item.title}
                      </a>
                    </Text>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <Text as="h3" color="contrast" size="sm">
                Company
              </Text>
              <ul className="mt-3 space-y-3">
                {COMPANY.map((item) => (
                  <li key={item.name}>
                    <Text size="sm" color="muted" asChild>
                      <a href={item.href} target="_blank" rel="noreferrer">
                        {item.name}
                      </a>
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="border-stroke/5 flex h-16 items-center border-t">
          <Text color="muted" size="xs">
            &copy; {new Date().getFullYear()} Flipside Crypto
          </Text>
        </div>
      </Container>
    </footer>
  );
}
